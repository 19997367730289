import { get } from './http'

export const commentList = (params) => {
  const url = '/ask/my'
  return get(url, params)
}

export const getReply = (params) => {
  const url = '/ask/get_answers'
  return get(url, params)
}
