import { get, post } from './http'

export const video = (params) => {
  const url = '/chapter/video'
  return get(url, params)
}

export const info = (params) => {
  const url = '/chapter/detail'
  return get(url, params)
}

export const commentList = (params) => {
  const url = '/video/get_comment'
  return get(url, params)
}

export const addComment = (data) => {
  const url = '/video/add_comment'
  return post(url, data)
}

export const addCommentReply = (data) => {
  const url = '/video/add_comment_reply'
  return post(url, data)
}

export const addReply = (data) => {
  const url = '/video/add_reply'
  return post(url, data)
}

export const getReply = (params) => {
  const url = '/video/get_reply'
  return get(url, params)
}

export const getQuestion = (params) => {
  const url = '/question/get'
  return get(url, params)
}

export const submitAnswer = (data) => {
  const url = '/question/post'
  return post(url, data)
}

export const videoLike = (data) => {
  const url = '/video/like'
  return post(url, data)
}

export const playerEnd = (data) => {
  const url = '/chapter/player_end'
  return post(url, data)
}
